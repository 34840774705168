var HeadineType;

(function (HeadineType) {
  HeadineType["H1"] = "H1";
  HeadineType["H2"] = "H2";
  HeadineType["H3"] = "H3";
  HeadineType["H4"] = "H4";
  HeadineType["H5"] = "H5";
  HeadineType["H6"] = "H6";
})(HeadineType || (HeadineType = {}));

var HeadlineWeight;

(function (HeadlineWeight) {
  HeadlineWeight["LIGHT"] = "LIGHT";
  HeadlineWeight["REGULAR"] = "REGULAR";
  HeadlineWeight["BOLD"] = "BOLD";
})(HeadlineWeight || (HeadlineWeight = {}));

export { HeadineType, HeadlineWeight };